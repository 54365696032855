import { css, ThemeProps, FlattenInterpolation } from "styled-components"
import { pxToRem } from "common/functions"

export type T_SizesType = { [key: string]: string }

export type T_TypoSettingsType = {
  [key: string]: {
    general?: FlattenInterpolation<ThemeProps<any>>
    sizes: T_SizesType
  }
}

export const typography: T_TypoSettingsType = {
  mega: {
    general: css`
      line-height: 1.1;
    `,
    sizes: {
      xs: pxToRem(100),
      sm: pxToRem(120),
      md: pxToRem(160),
      lg: pxToRem(240),
    },
  },
  advert: {
    general: css`
      line-height: 1.1;
    `,
    sizes: {
      xs: pxToRem(48),
      sm: pxToRem(56),
      md: pxToRem(56),
      lg: pxToRem(100),
      xl: pxToRem(100),
      xxl: pxToRem(96),
    },
  },
  headline: {
    general: css`
      font-weight: 500;
      line-height: 1.1;
    `,
    sizes: {
      xs: pxToRem(32),
      sm: pxToRem(40),
      md: pxToRem(48),
      lg: pxToRem(56),
      xl: pxToRem(64),
    },
  },
  subline: {
    general: css`
      font-weight: 500;
      line-height: 1.15;
      font-size: 2.8vw;
    `,
    sizes: {
      xs: pxToRem(30),
      sm: pxToRem(34),
      md: pxToRem(42),
      lg: "3.5vw",
      xxxl: "3vw",
    },
  },
  sublineSecondary: {
    general: css`
      font-family: "Halenoir ", sans-serif;
      font-weight: 500;
      line-height: 1.25;
    `,
    sizes: {
      xs: pxToRem(24),
      sm: pxToRem(28),
      md: pxToRem(32),
      lg: "2.4vw",
      xxxl: "1.8vw",
    },
  },
  title: {
    general: css`
      font-weight: 500;
      line-height: 1.35;
    `,
    sizes: {
      xs: pxToRem(22),
      sm: pxToRem(26),
      lg: "1.9vw",
      xxxl: "1.5vw",
    },
  },
  paragraph: {
    general: css``,
    sizes: {
      xs: pxToRem(20),
      sm: pxToRem(22),
      lg: "1.6vw",
      xxxl: "1.3vw",
    },
  },
  body: {
    general: css`
      line-height: 1.45;
    `,
    sizes: {
      xs: pxToRem(15),
      sm: pxToRem(16),
      md: pxToRem(17),
      lg: pxToRem(18),
      xl: pxToRem(20),
    },
  },
  small: {
    general: css``,
    sizes: {
      xs: pxToRem(14),
      sm: pxToRem(14),
      md: pxToRem(14),
      lg: pxToRem(15),
      xl: pxToRem(17),
    },
  },
  xSmall: {
    general: css``,
    sizes: {
      xs: pxToRem(11),
      sm: pxToRem(12),
      md: pxToRem(12),
      lg: pxToRem(13),
      xl: pxToRem(15),
    },
  },
}
