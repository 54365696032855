import { applyModifiers } from "common/functions"
import styled, { css } from "styled-components"

export type T_MainHeader = keyof typeof Modifiers
export interface I_MainHeader {
  modifiers?: T_MainHeader[]
}

const Modifiers = {
  isAbsolute: () => css`
    position: absolute;
  `,
}

export const MainHeader = styled.div<I_MainHeader>`
  z-index: 12;
  top: 0;
  left: 0;
  width: 100%;

  ${({ modifiers }) => applyModifiers(Modifiers, modifiers)}
`
