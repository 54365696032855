import React from "react"
import { v4 as uuidv4 } from "uuid"
import { Helmet } from "react-helmet"

interface Props {
  description: string
  lang: string
  title: string
}

export const Seo: React.FC<Props> = props => {
  const { lang, title, description } = props

  const buildMetaProps = () => {
    return [
      {
        name: `og:title`,
        content: title,
      },
      {
        name: `og:description`,
        content: description,
      },
      {
        name: "og:image",
        content: `https://zeroco2.ag/seo-image.jpg`,
      },
      {
        name: `og:type`,
        content: `website`,
      },
    ]
  }

  const buildTwitterTags = () => {
    return [
      {
        name: `twitter:card`,
        content: `summary_large_image"`,
      },
      {
        name: `twitter:title`,
        content: title,
      },
      {
        name: `twitter:description`,
        content: description,
      },
      {
        name: "twitter:image",
        content: `https://zeroco2.ag/seo-image.jpg`,
      },
    ]
  }

  const metaProps = buildMetaProps()
  const twitterProps = buildTwitterTags()

  const ogMetaTag = (name: string, content: string) => (
    <meta key={uuidv4()} property={name} content={content}></meta>
  )

  const twitterTag = (name: string, content: string) => (
    <meta key={uuidv4()} name={name} content={content}></meta>
  )

  return (
    <Helmet>
      <html lang={lang} />
      <title>{title}</title>
      <meta name="description" content={description}></meta>
      <meta name="robots" content="index, follow" />

      {metaProps.map(({ name, content }) => ogMetaTag(name, content))}

      {twitterProps.map(({ name, content }) => twitterTag(name, content))}
    </Helmet>
  )
}
