import React from "react"
import { Svg } from "components/Svg"
import { Logo as L } from "./styles"
import { T_Logo } from "./styles/Logo.styles"

interface Props {
  inverted: boolean
  lang: string
  modifiers?: T_Logo[]
}

export const Logo: React.FC<Props> = props => {
  const { inverted, modifiers, lang } = props
  return (
    <L.Block
      to={lang === "en" ? "/en" : "/"}
      modifiers={inverted ? [...modifiers, "inverted"] : modifiers}
    >
      <Svg type={"getLogo"} />
    </L.Block>
  )
}
