import styled from "styled-components"
import { lighten } from "polished"
import { motion as m } from "framer-motion"
import { color } from "common/settings"

export const MainHeaderNavigation = styled(m.div)`
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: ${color.inverted};
`

export const MainHeaderNavigationItem = styled(m.div)`
  border-bottom: 1px solid ${lighten(0.32, color.light)};
`
