import { TeaserLinks as Block } from "./TeaserLinks.styles"
import {
  TeaserLinksItem as Element,
  TeaserLinksItemDivider as ItemDivider,
  TeaserLinksItemDividerInner as DividerInner,
} from "./TeaserLinksItem.styles"

export const TeaserLinks = {
  Block,
  Item: {
    Element,
    Divider: {
      Element: ItemDivider,
      Inner: DividerInner,
    },
  },
}
