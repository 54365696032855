import React from "react"

import { ReactComponent as ZeroLogo } from "common/svg/zero-emissions.svg"
import { ReactComponent as GetLogo } from "common/svg/zero-emission-ag.svg"
import { ReactComponent as GetLogoInline } from "common/svg/get-logo.svg"
import { ReactComponent as Quote } from "common/svg/quote.svg"
import { ReactComponent as Circles } from "common/svg/circles.svg"
import { ReactComponent as CirclesInverted } from "common/svg/circles-inverted.svg"
import { ReactComponent as Play } from "common/svg/play.svg"

const svg = {
  circles: Circles,
  circlesInverted: CirclesInverted,
  getLogo: GetLogo,
  getLogoInline: GetLogoInline,
  quote: Quote,
  play: Play,
  zeroLogo: ZeroLogo,
}

export type T_Type = keyof typeof svg

export interface SvgProps {
  type: T_Type
  style?: React.CSSProperties
  width?: number
  height?: number
  classes?: string
}

export const Svg: React.FC<SvgProps> = props => {
  const { type } = props

  const generateSvg = () => {
    if (typeof svg[type] !== undefined) {
      const Component = svg[type]
      return <Component {...props} />
    }
  }

  return <>{generateSvg()}</>
}
