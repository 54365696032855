import React from "react"
import { MainHeader } from "components/MainHeader"
import { Footer } from "components/Footer"
import { Seo } from "components/Seo"

interface Props {
  children: React.ReactNode
  description: string
  location: Location
  lang: string
  mainHeaderPosAbsolute: boolean
  inverted?: boolean
  title: string
}

export const ContentLayout: React.FC<Props> = props => {
  const {
    children,
    inverted,
    location,
    lang,
    title,
    description,
    mainHeaderPosAbsolute,
  } = props

  return (
    <>
      <Seo lang={lang} title={title} description={description} />
      <div style={{ width: "100vw" }}>
        <MainHeader
          inverted={inverted}
          isAbsolute={mainHeaderPosAbsolute}
          lang={lang}
          location={location}
        />
        {children}
        <Footer lang={lang} location={location} />
      </div>
    </>
  )
}
