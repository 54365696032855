import React, { useRef } from "react"
import { motion as m, useScroll, useTransform } from "framer-motion"

interface Props {
  distance?: any
  children: React.ReactNode
}

export const ScrollWrapper: React.FC<Props> = props => {
  const { children, distance } = props

  const refTarget = useRef<HTMLDivElement>(null)
  const { scrollYProgress } = useScroll({
    target: refTarget,
    offset: distance || ["start end", "end center"],
  })

  const y = useTransform(scrollYProgress, [0, 1], [30, 0])
  const opacity = useTransform(scrollYProgress, [0, 1], [0, 1])

  return (
    <div ref={refTarget}>
      <m.div style={{ y, opacity }}>{children}</m.div>
    </div>
  )
}
