import React, { useEffect, useState } from "react"
import { transition } from "common/settings"

import { MainHeader as MH } from "../styles"
import { anim } from "../animation"

interface Props {
  open: boolean
  inverted: boolean
  delegateOpen: (open: boolean) => void
}

export const MainHeaderMenu: React.FC<Props> = props => {
  const { open: o, inverted, delegateOpen } = props

  return (
    <MH.Menu.Element inverted={inverted} onClick={() => delegateOpen(!o)}>
      <MH.Menu.Bar
        initial={false}
        animate={o ? "open" : "close"}
        variants={anim.I}
        transition={transition}
      />
      <MH.Menu.Bar
        initial={false}
        animate={o ? "open" : "close"}
        variants={anim.II}
        transition={transition}
      />
      <MH.Menu.Bar
        initial={false}
        animate={o ? "open" : "close"}
        variants={anim.III}
        transition={transition}
      />
    </MH.Menu.Element>
  )
}
