import styled from "styled-components"
import { rgba } from "polished"

import { pxToRem } from "common/functions"
import { color } from "common/settings"

export const TeaserLinksItemDividerInner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: ${pxToRem(1)};

  background-color: ${color.middle};
  overflow: hidden;

  transition: transform 0.24s ease-in-out;
  transform: translateX(-100%);
`

export const TeaserLinksItem = styled.div`
  position: relative;

  &:hover ${TeaserLinksItemDividerInner} {
    transform: translateX(0%);
  }
`

export const TeaserLinksItemDivider = styled.div`
  position: relative;
  width: 100%;
  height: ${pxToRem(1)};

  background-color: ${rgba(color.light, 0.32)};
  overflow: hidden;
`
