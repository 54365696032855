export const color = {
  light: "#898989",
  middle: "#1B4D47",
  dark: "#083721",
  highlight: "#8B8164",
  highlightLight: "#C7BDA2",
  contrast: "#000000",
  inverted: "#ffffff",
  darkBlue: "#15202C",
}
