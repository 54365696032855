import styled, { css } from "styled-components"
import { Link } from "gatsby"

import { applyModifiers, pxToRem } from "common/functions"
import { color } from "common/settings"
import { breaks, gaps } from "common/styles"
import { LogoCircle } from "./LogoCircle.styles"

export type T_Logo = keyof typeof Modifiers

interface I_Logo {
  modifiers?: T_Logo[]
}

const Modifiers = {
  inMainHeader: () => css`
    position: relative;
    z-index: 12;
    width: ${pxToRem(124)};

    ${breaks.md.up} {
      width: ${pxToRem(148)};
    }

    ${breaks.lg.up} {
      width: ${pxToRem(148)};
    }

    & ${LogoCircle} {
      width: ${pxToRem(118)};
      height: ${pxToRem(118)};

      ${breaks.lg.up} {
        width: ${pxToRem(172)};
        height: ${pxToRem(172)};
      }
    }
  `,
  inverted: () => css`
    & svg {
      & .zero-emission-ag_svg__logo-main {
        fill: ${color.inverted};
      }

      & .zero-emission-ag_svg__logo-side {
        fill: ${color.inverted};
      }
    }
  `,
}

export const Logo = styled(Link)<I_Logo>`
  position: relative;
  display: block;
  width: ${pxToRem(220)};

  ${breaks.md.up} {
    width: ${pxToRem(280)};
  }

  ${breaks.lg.up} {
    width: ${pxToRem(320)};
  }

  & svg {
    fill: ${color.dark};

    & .zero-emission-ag_svg__logo-main {
      fill: ${color.dark};
    }

    & .zero-emission-ag_svg__logo-side {
      fill: ${color.highlightLight};
    }
  }

  & ${LogoCircle} {
    width: ${pxToRem(240)};
    height: ${pxToRem(240)};

    ${breaks.md.up} {
      width: ${pxToRem(320)};
      height: ${pxToRem(320)};
    }

    ${breaks.lg.up} {
      width: ${pxToRem(480)};
      height: ${pxToRem(480)};
    }
  }

  ${({ modifiers }) => applyModifiers(Modifiers, modifiers)}
`
