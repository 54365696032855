import React from "react"
import { v4 as uuidv4 } from "uuid"
import { orderBy } from "lodash"
import { Link, useStaticQuery, graphql } from "gatsby"
import { Grid, GridItem } from "components/Grid"
import { Text } from "components/Text"
import { ScrollWrapper } from "components/ScrollWrapper"

import { TeaserLinks as TL } from "./styles"

interface I_NavItem {
  page: {
    type: any
    order: number
    lang: string
    slug: string
    title: string
  }
}

interface Props {
  location: Location
  lang: string
}

function getTeaserLinks(): { allContent: { pages: I_NavItem[] } } {
  return useStaticQuery(graphql`
    query getLinks {
      allContent: allContentJson {
        pages: edges {
          page: node {
            order
            type
            lang
            slug
            title
          }
        }
      }
    }
  `)
}

function removeTrailingSlash(str: string) {
  return str.replace(/\/+$/, "")
}

export const TeaserLinks: React.FC<Props> = props => {
  const {
    allContent: { pages },
  } = getTeaserLinks()

  const { lang, location } = props

  const purgedLinks = pages
    .filter(({ page }) => page.type !== "article")
    .filter(
      ({ page }) =>
        page.slug !== "home" &&
        page.slug !== "legal" &&
        page.slug !== "about" &&
        page.slug !== "en/home" &&
        page.slug !== "en/legal" &&
        page.slug !== "en/about" &&
        page.slug !== "en/terms-and-conditions" &&
        page.slug !== "en/data-protection" &&
        page.slug !== "en/scope/case-study-get" &&
        page.slug !== "terms-and-conditions" &&
        page.slug !== "data-protection" &&
        page.slug !== "scope/case-study-get" &&
        page.slug !== removeTrailingSlash(location.pathname.slice(1))
    )

  const purgedLangNavigation = purgedLinks.filter(
    ({ page }: I_NavItem) => page.lang === lang
  )

  const orderedNavigation = orderBy(
    purgedLangNavigation,
    ({ page }) => page.order,
    ["asc"]
  ) as I_NavItem[]

  const renderLink = (title: string, to: string, index: number) => (
    <ScrollWrapper key={index}>
      <Text key={uuidv4()} modifiers={["spacingBottom"]} spacingBottom={"xxl"}>
        <TL.Item.Element>
          <Text
            key={uuidv4()}
            modifiers={["subline", "spacingBottom", "fontWeight"]}
            weight={300}
            spacingBottom={"xxl"}
          >
            <Link
              style={{ display: "flex" }}
              to={to === "home" ? "/" : `/${to}`}
            >
              {Text({
                modifiers: ["paragraph", "displayInlineBlock", "spacingRight"],
                spacingRight: "l",
                children: `0${index + 1}.`,
              })}
              {title}
            </Link>
          </Text>
          <TL.Item.Divider.Element>
            <TL.Item.Divider.Inner />
          </TL.Item.Divider.Element>
        </TL.Item.Element>
      </Text>
    </ScrollWrapper>
  )

  return (
    <>
      <TL.Block>
        <Grid
          modifiers={["maxWidth", "spacingTop", "spacingBottom"]}
          spacingTop={"xl"}
          spacingBottom={"xl"}
        >
          <GridItem>
            <Text
              modifiers={["spacingTop", "spacingBottom"]}
              spacingTop={"xl"}
              spacingBottom={"xl"}
            >
              <></>
            </Text>
            {ScrollWrapper({
              children: (
                <Text
                  modifiers={[
                    "paragraph",
                    "fontAlignCenter",
                    "spacingTop",
                    "spacingBottom",
                  ]}
                  spacingTop={"m"}
                  spacingBottom={"xxl"}
                >
                  Learn More
                </Text>
              ),
            })}
          </GridItem>
          <GridItem>
            {orderedNavigation.map(
              ({ page }, index) =>
                page.slug !== location.pathname.slice(0, 1) &&
                renderLink(page.title, page.slug, index)
            )}
          </GridItem>
        </Grid>
      </TL.Block>
    </>
  )
}
