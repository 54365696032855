import React from "react"
import { motion as m } from "framer-motion"
import { orderBy } from "lodash"
import { useStaticQuery, graphql, Link } from "gatsby"
import { AnimatePresence } from "framer-motion"

import { transition } from "common/settings"
import { Grid, GridItem } from "components/Grid"

import { MainHeader as MH } from "../styles"
import {
  MainHeaderNavigation as anim,
  MainHeaderNavigationItem as animItem,
} from "../animation"
import { Text } from "components/Text"

interface I_NavItem {
  page: {
    order: number
    lang: string
    slug: string
    title: string
  }
}

interface Props {
  open: boolean
  lang: string
  delegateOpen: (open: boolean) => void
}

function getNavigationElements() {
  return useStaticQuery(graphql`
    query getNavigation {
      allContent: allContentJson {
        pages: edges {
          page: node {
            order
            lang
            slug
            title
          }
        }
      }
    }
  `)
}

export const MainHeaderNavigation: React.FC<Props> = props => {
  const { open, lang, delegateOpen } = props

  const {
    allContent: { pages },
  } = getNavigationElements()

  const navigation = pages.filter(
    ({ page }: I_NavItem) =>
      page.slug !== "home" &&
      page.slug !== "terms-and-conditions" &&
      page.slug !== "data-protection" &&
      page.slug !== "en/home" &&
      page.slug !== "en/terms-and-conditions" &&
      page.slug !== "en/data-protection" &&
      page.slug !== "en/about" &&
      page.slug !== "scope/case-study-get" &&
      page.slug !== "magazine/ev-cars" &&
      page.slug !== "about" &&
      page.slug !== "contact" &&
      page.slug !== "hr/home" &&
      page.slug !== "hr/pravno" &&
      page.slug !== "legal"
  )

  const purgedLangNavigation = navigation.filter(
    ({ page }: I_NavItem) => page.lang === lang
  )

  const orderedNavigation = orderBy(
    purgedLangNavigation,
    ({ page }) => page.order,
    ["asc"]
  ) as I_NavItem[]

  const renderNavigation = (el: JSX.Element) => (
    <MH.Navigation.Element
      initial={"close"}
      animate={"open"}
      exit={"close"}
      variants={anim}
      transition={{ ...transition }}
    >
      <Grid modifiers={["maxWidth"]}>
        <GridItem
          responsive={{ sm: { span: 12 } }}
          modifiers={["flex", "alignCenter"]}
        >
          <div style={{ width: "100%" }}>{el}</div>
        </GridItem>
      </Grid>
    </MH.Navigation.Element>
  )

  const renderNavigationItem = ({ page }: I_NavItem, index: number) => (
    <MH.Navigation.Item
      custom={index}
      initial={"close"}
      animate={"open"}
      exit={"close"}
      variants={animItem}
    >
      <Text
        type="span"
        modifiers={[
          "block",
          "paragraph",
          "fontWeight",
          "spacingTop",
          "spacingBottom",
        ]}
        weight={300}
        spacingTop={"l"}
        spacingBottom={"l"}
      >
        <Link
          onClick={() => {
            delegateOpen(!open)
          }}
          to={`/${page.slug}`}
          style={{ display: "flex" }}
        >
          <Text
            modifiers={["small", "displayInlineBlock", "spacingRight"]}
            spacingRight={"l"}
          >
            0{index + 1}.
          </Text>
          {page.title}
        </Link>
      </Text>
    </MH.Navigation.Item>
  )

  return (
    <AnimatePresence exitBeforeEnter>
      {open &&
        renderNavigation(
          <>
            {orderedNavigation.map((page: I_NavItem, index: number) =>
              renderNavigationItem(page, index)
            )}
          </>
        )}
    </AnimatePresence>
  )
}
