import React, { useState } from "react"
import { Link } from "gatsby"

import { Text } from "components/Text"
import { Grid, GridItem } from "components/Grid"
import { Logo } from "components/Logo"
import { MainHeaderMenu } from "./MainHeaderMenu"
import { MainHeaderNavigation } from "./MainHeaderNavigation"

import { MainHeader as MH } from "./styles"
import { color } from "common/settings"

interface Props {
  inverted: boolean
  location: Location
  lang: string
  isAbsolute: boolean
}

export const MainHeader: React.FC<Props> = props => {
  const { isAbsolute, location, lang, inverted } = props
  const [open, setOpen] = useState<boolean>(false)

  const renderNavigation = () => (
    <MainHeaderNavigation
      lang={lang}
      open={open}
      delegateOpen={open => setOpen(open)}
    />
  )

  const renderDropdown = () => {
    return (
      <div style={{ position: "relative", cursor: "pointer" }}>
        <Text modifiers={["spacingRight"]} spacingRight={"l"}>
          <div>
            <Link
              style={{ textDecoration: "none" }}
              to={lang === "en" ? "/" : "/en"}
            >
              <Text
                modifiers={["body", "color"]}
                color={open ? "contrast" : inverted ? "inverted" : "contrast"}
              >
                {lang === "en" ? "DE" : "EN"}
              </Text>
            </Link>
          </div>
        </Text>
      </div>
    )
  }

  return (
    <MH.Block modifiers={isAbsolute ? ["isAbsolute"] : undefined}>
      <div style={{ position: "relative", zIndex: 38 }}>
        <Grid
          modifiers={["maxWidth", "spacingTop", "spacingBottom"]}
          spacingTop={"xl"}
          spacingBottom={"xl"}
        >
          <GridItem responsive={{ xs: { span: 6 }, sm: { span: 6 } }}>
            <Logo
              lang={lang}
              inverted={open ? false : inverted}
              modifiers={["inMainHeader"]}
            />
          </GridItem>
          <GridItem
            responsive={{ xs: { span: 6 }, sm: { span: 6 } }}
            modifiers={["flex", "justifyEnd", "alignCenter"]}
          >
            {renderDropdown()}
            <MainHeaderMenu
              inverted={!open ? inverted : false}
              open={open}
              delegateOpen={open => setOpen(open)}
            />
          </GridItem>
        </Grid>
      </div>
      {renderNavigation()}
      {/* {location.pathname.includes("scope") && renderSubNavigation()} */}
    </MH.Block>
  )
}
